@use "../../../styles/colors" as c;
.hero {
  margin: 10vh auto 20vh;
  width: min(100% - 6rem, 70rem);
  &__highlight {
    color: c.$mine-white;
  }
  &__main-name {
    color: c.$mine-white;
  }
  &__main-mission {
    color: c.$mine-lightest-slate;
  }
  &__statement {
    color: c.$mine-slate;
  }

  &__quote {
    margin: 2rem 0;

    &-heading {
      color: c.$mine-lightest-blue;
    }
  }

  &__contact-link {
    color: c.$mine-lightest-blue;
  }
}
