@use "../../../styles/colors" as c;
@use "../../../styles/breakpoints" as b;

.about {
  margin: 10vh auto 5vh;
  width: min(100% - 6rem, 70rem);

  &__heading {
    color: c.$mine-lightest-blue;
  }

  &__first-p {
    p {
      margin: 1rem 0;
    }
  }

  &__graph {
    display: none;
    justify-content: center;
  }

  &__anime-quote {
    margin: 2rem 0;
  }

  &__anime-heading {
    color: c.$mine-lightest-blue;
  }

  &__skills {
    margin: 5vh 0;
  }
  &__skills-heading {
    color: c.$mine-lightest-blue;
  }

  &__skills-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @include b.breakpoint("medium") {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  // &__skills-list {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: baseline;
  // }

  &__skill-item {
    margin: 2rem 2rem;
    width: 8rem;
    height: 8rem;
    text-align: center;
  }

  &__skill-img {
    height: 5rem;
  }

  &__skill-name {
    text-align: center;
  }
}
