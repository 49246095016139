@use "./colors" as c;
body {
  background-color: c.$mine-darkest-blue;
}

h1 {
  font-size: 3.6rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2.6rem;
}
h4 {
  font-size: 2.2rem;
}

p {
  font-size: 1.8rem;
}
