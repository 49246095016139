@use "../../../styles/colors" as c;

.projects {
  margin: 20vh auto;
  width: min(100% - 6rem, 70rem);
  &__heading {
    color: c.$mine-lightest-blue;
  }

  &__jclear {
    &-quote {
      margin: 2rem 0;
    }

    &-heading {
      color: c.$mine-lightest-blue;
    }

    &-source {
      margin: 2rem 0;
      font-size: medium;

      a {
        text-decoration: underline;
        font-style: italic;

        &:hover {
          color: c.$mine-light-blue;
        }
      }
    }
  }
}
