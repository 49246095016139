@use "../../../styles/colors" as c;
@use "../../../styles/breakpoints" as b;

.header {
  margin: 3rem 2rem;
  &__nav {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include b.breakpoint("medium") {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
    @include b.breakpoint("large") {
      justify-content: space-evenly;
    }
  }

  &__list {
    display: flex;
    column-gap: 1rem;
    max-width: 70vw;
  }

  &__logo {
    height: 6rem;
  }

  &__list-item {
    margin: 0 1.2rem;
    @include b.breakpoint("medium") {
      margin: 0 2rem;
    }
    a {
      color: c.$mine-white;
      font-size: 1.6rem;
      transition: all 1s;

      &:hover {
        color: c.$mine-lightest-blue;
      }
    }
  }

  &__resume-button {
    border: c.$mine-lightest-blue 2px solid;
    border-radius: 5px;
    padding: 5px;
    color: c.$mine-light-blue;

    &:hover {
      color: c.$mine-lightest-blue;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 3px solid green;
    }
  }
}
