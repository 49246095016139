$mine-darkest-blue: #172a3a;
$mine-dark-green: #004346;
$mine-light-blue: #508991;
$mine-lightest-blue: #75dddd;
$mine-slate: #8892b0;
$mine-light-slate: #a8b2d1;
$mine-lightest-slate: #ccd6f6;
$mine-white: #ffffff;
$mine-black: #000000;
// ===================//
